export const pools = [
  {
    pid: 0,
    stakedTokenName: "GUILD",
    stakedTokenSymbol: "GUILD",
    rewardTokenName:"GUILD",
    stakedTokenDecimals: 18,
    tokenAddress: "0x83e9f223e1edb3486f876ee888d76bfba26c475a",
    poolAddress: "0x3cd8ad561cc3c40af80a6c6d10e13482063ac0b9",
    getUrl:"https://app.uniswap.org/#/swap?inputCurrency=0x83e9f223e1edb3486f876ee888d76bfba26c475a&outputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    weight: 0.3,
    toFixed: 2
  },
  {
    pid: 1,
    stakedTokenName: "GUILD:USDC LP",
    stakedTokenSymbol: "GUILD:USDC LP",
    rewardTokenName:"GUILD",
    stakedTokenDecimals: 18,
    tokenAddress: "0xDCeC563A15b0dE0FDfC19c97D5EE18A5570Fed7F",
    poolAddress: "0x4b02bba3c9c950693353cd7596f69edd3bfa0a16",
    getUrl:"https://app.uniswap.org/#/add/v2/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/0x83E9F223e1edb3486F876EE888d76bFba26c475A?chain=mainnet",
    weight: 0.7,
    toFixed: 8
  },
];

