import React, { useEffect, useState } from "react";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footer/Footer";
import { useTranslation } from "react-i18next";
import { Notifier } from "features/common";
import { useConnectWallet } from "./redux/hooks";
import appStyle from "./jss/appStyle.js";
import createAppTheme from "./jss/appTheme";
import Background from "assets/img/background.png";
import LandingHeader from "./components/LandingHeader";
const useStyles = makeStyles(appStyle);

export default function App({ children }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = createAppTheme(true);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LandingHeader />
        <div
          style={{
            padding: 10,
            paddingTop: 60,
            background: `url(${Background})`,
            backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        >
          <div>
            {children}
            <Notifier />
          </div>
          <Footer />
        </div>
      </ThemeProvider>
    </StylesProvider>
  );
}
