export const contracts = {
  multicall: {
    address: "0x29de9dfa4adfe50c878fb2ffff8c1b28d9e24355",
  },
  liquidityMiningManager: {
    address: "0x2d9fd7ad43e75d1f991de6cc876d2285cf8ccbf0",
  },
  escrowedReward: {
    address: "0xb19e239db03ab1cb45894a5ed586bc2bbddea9c6",
  },
  view: {
    address: "0x3ae00bde1a1d0eefcd79edd9f1f658023fcecc29",
  },
  singleTokenPool: {
    address: "0x3cd8ad561cc3c40af80a6c6d10e13482063ac0b9",
  },
  lpPool: {
    address: "0x4b02bba3c9c950693353cd7596f69edd3bfa0a16",
  },
  uniswapLpPairAddress : {
    address: "0xDCeC563A15b0dE0FDfC19c97D5EE18A5570Fed7F"
  },
};
